import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { defineComponent, onMounted, ref } from 'vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useToTop from '@/hooks/useToTop';
import dayjs from 'dayjs';
import useTotalData from './hooks/useTotalData';
import useDepartData from './hooks/useDepartData';
import useDepartUserData from './hooks/useDepartUserData';
import CNoData from '@/components/CNoData.vue';
export default defineComponent({
  components: {
    CNoData: CNoData
  },
  name: 'WarehouseSpareParts',
  setup: function setup(props, ctx) {
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var emptyStringFormat = useEmptyStringFormat();
    var confrimDate = function confrimDate(val) {
      params.value.date = formatDate(val);
      userParams.value.date = formatDate(val);
      getTotalDataAsync();
      getDepartDataAsync();
    };
    var params = ref({
      date: dayjs().format('YYYY/MM/DD')
    });
    var _useTotalData = useTotalData(params),
      _useTotalData2 = _slicedToArray(_useTotalData, 3),
      totalData = _useTotalData2[0],
      summaryData = _useTotalData2[1],
      getTotalDataAsync = _useTotalData2[2];
    var _useDepartData = useDepartData(params),
      _useDepartData2 = _slicedToArray(_useDepartData, 2),
      departData = _useDepartData2[0],
      getDepartDataAsync = _useDepartData2[1];
    var goTop = useToTop();
    onMounted(function () {
      getTotalDataAsync();
      getDepartDataAsync();
    });
    var activeNames = ref('');
    var activeNamess = ref('');
    var userIndex = ref('');
    var userParams = ref({
      date: dayjs().format('YYYY/MM/DD'),
      depName: '',
      userName: ''
    });
    var _useDepartUserData = useDepartUserData(userParams),
      _useDepartUserData2 = _slicedToArray(_useDepartUserData, 2),
      userData = _useDepartUserData2[0],
      getDepartUserDataAsync = _useDepartUserData2[1];
    var changeUser = function changeUser(index, dep, user) {
      userIndex.value = index;
      userData.value = [];
      userParams.value.depName = dep;
      userParams.value.userName = user;
      getDepartUserDataAsync();
    };
    return {
      emptyStringFormat: emptyStringFormat,
      summaryData: summaryData,
      activeNames: activeNames,
      activeNamess: activeNamess,
      goTop: goTop,
      departData: departData,
      confrimDate: confrimDate,
      changeUser: changeUser,
      userIndex: userIndex,
      userData: userData
    };
  }
});