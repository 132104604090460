import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/first.png';
import _imports_1 from '@/assets/img/second.png';
import _imports_2 from '@/assets/img/third.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-78e5ec3f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "rankListItem flex align-center"
};
var _hoisted_2 = {
  class: "num-box"
};
var _hoisted_3 = {
  key: 0,
  class: "logo",
  src: _imports_0
};
var _hoisted_4 = {
  key: 1,
  class: "logo",
  src: _imports_1
};
var _hoisted_5 = {
  key: 2,
  class: "logo",
  src: _imports_2
};
var _hoisted_6 = {
  key: 3,
  class: "num"
};
var _hoisted_7 = {
  class: "title"
};
var _hoisted_8 = {
  class: "weight"
};
var _hoisted_9 = {
  class: "detail"
};
var _hoisted_10 = {
  class: "flex align-center justify-between my-1"
};
var _hoisted_11 = {
  class: "name"
};
var _hoisted_12 = {
  class: "flex align-center justify-between pb-05"
};
var _hoisted_13 = {
  class: "text"
};
var _hoisted_14 = {
  class: "text"
};
var _hoisted_15 = {
  class: "flex align-center justify-between pb-05"
};
var _hoisted_16 = {
  class: "text"
};
var _hoisted_17 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_collapse_item = _resolveComponent("van-collapse-item");
  return _openBlock(), _createBlock(_component_van_collapse_item, {
    title: _ctx.item.group_name,
    name: _ctx.index,
    class: "mb-2"
  }, {
    title: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.index === 0 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _ctx.index === 1 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), _ctx.index === 2 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true), _ctx.index > 2 ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.index + 1), 1)) : _createCommentVNode("", true)]), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.group_name), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.item.money) + "元", 1)])];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.info, function (iten, indey) {
        return _openBlock(), _createElementBlock("div", {
          key: indey,
          class: "detail-item"
        }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(iten.stone_name) + " " + _toDisplayString(iten.stone_spec ? '- ' + iten.stone_spec : ''), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", _hoisted_13, "数量：" + _toDisplayString(iten.stone_stock), 1), _createElementVNode("span", _hoisted_14, _toDisplayString(iten.money) + "元", 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("span", _hoisted_16, "上限：" + _toDisplayString(iten.upper_limit) + "元", 1), _createElementVNode("span", _hoisted_17, "下限：" + _toDisplayString(iten.lower_limit) + "元", 1)])]);
      }), 128))])];
    }),
    _: 1
  }, 8, ["title", "name"]);
}