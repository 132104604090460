import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  ITotalData,
  IRank,
  IDepartData,
  IDepartUserData
} from '@/service/warehouse/requisition/type'
enum DashboardApi {
  getTotalData = '/saleData/reportRequisitionSpareStatistics',
  getRank = '/saleData/reportRequisitionSpareRank'
}

// 获取合计数据
export function getTotalData(data: {
  date: string
}): Promise<IRequestResult<ITotalData>> {
  return hyRequest.post<IRequestResult<ITotalData>>({
    url: DashboardApi.getTotalData,
    data
  })
}

// 获取部门数据
export function getDepartData(data: {
  date: string
}): Promise<IRequestResult<IDepartData>> {
  return hyRequest.post<IRequestResult<IDepartData>>({
    url: '/saleData/reportRequisitionSpareDep',
    data
  })
}

// 获取部门用户数据
export function getDepartUserData(data: {
  date: string
  depName: string
  userName: string
}): Promise<IRequestResult<IDepartUserData>> {
  return hyRequest.post<IRequestResult<IDepartUserData>>({
    url: '/saleData/reportRequisitionSpareDepUserStone',
    data
  })
}

//获取备品备件排行列表
export function getRank(data: {
  date: string
}): Promise<IRequestResult<IRank>> {
  return hyRequest.post<IRequestResult<IRank>>({
    url: DashboardApi.getRank,
    data: data
  })
}
