import { ref, Ref } from 'vue'
import { getTrend } from '@/service/warehouse/spareParts'
import { IRankItem } from '@/service/warehouse/spareParts/type'

export default function useStockTrend(): [Ref<any[]>, () => Promise<void>] {
  const trendData = ref<any[]>([])
  const params = ref({ limit: 30, page: 1 })
  const getTrendAsync = async () => {
    const res = await getTrend(params.value)
    trendData.value = res.data.data
  }

  return [trendData, getTrendAsync]
}
