import { ref, Ref, computed, ComputedRef } from 'vue'
import { getDepartData } from '@/service/warehouse/requisition'
import { IDepartListItem } from '@/service/warehouse/requisition/type'

export default function useDepartData(
  params: Ref<{
    date: string
  }>
): [Ref<IDepartListItem[]>, () => Promise<void>] {
  const departData = ref<IDepartListItem[]>([])
  const getDepartDataAsync = async () => {
    const res = await getDepartData(params.value)
    departData.value = res.data.list
  }

  return [departData, getDepartDataAsync]
}
