import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useToTop from '@/hooks/useToTop';
import RankListItem from './cpns/RankListItem.vue';
import CNothing from '@/components/CNothing.vue';
import { LineEchart } from '@/components/page-echarts';
import useTotalData from './hooks/useTotalData';
import useStockRank from './hooks/useStockRank';
import useStockTrend from './hooks/useStockTrend';
export default defineComponent({
  name: 'WarehouseSpareParts',
  components: {
    RankListItem: RankListItem,
    CNothing: CNothing,
    LineEchart: LineEchart
  },
  setup: function setup(props, ctx) {
    var emptyStringFormat = useEmptyStringFormat();
    var _useTotalData = useTotalData(),
      _useTotalData2 = _slicedToArray(_useTotalData, 3),
      summaryData = _useTotalData2[1],
      getTotalDataAsync = _useTotalData2[2];
    var _useStockRank = useStockRank(),
      _useStockRank2 = _slicedToArray(_useStockRank, 5),
      rankData = _useStockRank2[0],
      loading = _useStockRank2[1],
      finished = _useStockRank2[2],
      getRankAsync = _useStockRank2[3],
      onLoad = _useStockRank2[4];
    var _useStockTrend = useStockTrend(),
      _useStockTrend2 = _slicedToArray(_useStockTrend, 2),
      trendData = _useStockTrend2[0],
      getTrendAsync = _useStockTrend2[1];
    var goTop = useToTop();
    onMounted(function () {
      getTotalDataAsync();
      getRankAsync();
      getTrendAsync();
    });
    var activeName = ref();
    var saleTrend = computed(function () {
      var list = trendData.value.map(function (item) {
        return item.money;
      });
      var xLabels = trendData.value.map(function (item) {
        return "".concat(item.date.slice(4, 6), "\u6708").concat(item.date.slice(6), "\u65E5");
      });
      return {
        lineData: list,
        xLabels: xLabels,
        legendData: ['库存趋势'],
        yName: '',
        height: '200px',
        showDetail: true,
        formatter: function formatter(params) {
          var res = "\n              <div >\n                <div class=\"flex align-center\">\n                  <span class=\"ml-2\">".concat(params[0].axisValue, "</span>\n                </div>\n              ");
          params.forEach(function (item) {
            res += "\n                <div >\n                  ".concat(item.marker, "\n                  <span style=\"font-size: 12px;\">\u5E93\u5B58\u91D1\u989D</span>\n                  <span style=\"font-weight:bold;font-size: 12px;\">").concat(item.value, "\u4E07\u5143</span>\n                </div>\n              ");
          });
          res += '</div>';
          return res;
        }
      };
    });
    return {
      emptyStringFormat: emptyStringFormat,
      summaryData: summaryData,
      rankData: rankData,
      onLoad: onLoad,
      loading: loading,
      finished: finished,
      goTop: goTop,
      activeName: activeName,
      saleTrend: saleTrend
    };
  }
});