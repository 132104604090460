import { ref, Ref, computed, ComputedRef } from 'vue'
import { getDepartUserData } from '@/service/warehouse/requisition'
import { IDepartUserListItem } from '@/service/warehouse/requisition/type'

export default function useDepartUserData(
  userParams: Ref<{
    date: string
    depName: string
    userName: string
  }>
): [Ref<IDepartUserListItem[]>, () => Promise<void>] {
  const userData = ref<IDepartUserListItem[]>([])
  const getDepartUserDataAsync = async () => {
    const res = await getDepartUserData(userParams.value)
    userData.value = res.data.list
  }

  return [userData, getDepartUserDataAsync]
}
