import { ref, Ref, computed, ComputedRef } from 'vue'
import { getTotalData } from '@/service/warehouse/spareParts'
import { ITotalData } from '@/service/warehouse/spareParts/type'

export default function useTotalData(): [
  Ref<ITotalData>,
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  () => Promise<void>
] {
  const totalData = ref<ITotalData>({
    money: 0,
    stock: 0,
    month_money: 0
  })
  const getTotalDataAsync = async () => {
    const res = await getTotalData()
    totalData.value = res.data
  }
  const summaryData = computed(() => {
    return [
      { num: totalData.value?.stock, desc: '物料总数/样' },
      { num: totalData.value?.money, desc: '总金额/万' },
      { num: totalData.value?.month_money, desc: '月初库存金额/万' }
    ]
  })

  return [totalData, summaryData, getTotalDataAsync]
}
