import { hyRequest1 } from '@/service'
import { IRequestResult } from '@/service/types'
import { ITotalData, IRank } from '@/service/warehouse/spareParts/type'
enum DashboardApi {
  getTotalData = '/report/report/spareCount',
  getRank = '/report/report/spareRankNew',
  getSpareDailyCount = '/report/report/spareDailyCount'
}

// 获取合计数据
export function getTotalData(): Promise<IRequestResult<ITotalData>> {
  return hyRequest1.get<IRequestResult<ITotalData>>({
    url: DashboardApi.getTotalData
  })
}
//获取备品备件排行列表
export function getRank(params: {
  limit: number
  page: number
}): Promise<IRequestResult<IRank>> {
  return hyRequest1.get<IRequestResult<IRank>>({
    url: `${DashboardApi.getRank}?limit=${params.limit}&page=${params.page}`
  })
}

export function getTrend(params: {
  limit: number
  page: number
}): Promise<IRequestResult> {
  return hyRequest1.get<IRequestResult>({
    url: `${DashboardApi.getSpareDailyCount}?limit=${params.limit}&page=${params.page}`
  })
}
