import { ref, Ref } from 'vue'
import { getRank } from '@/service/warehouse/spareParts'
import { IRankItem } from '@/service/warehouse/spareParts/type'

export default function useStockRank(): [
  Ref<IRankItem[]>,
  Ref<boolean>,
  Ref<boolean>,
  () => Promise<void>,
  () => void
] {
  const loading = ref(false)
  const finished = ref(false)
  const rankData = ref<IRankItem[]>([])
  const params = ref({ limit: 10, page: 1 })
  const getRankAsync = async () => {
    try {
      loading.value = true
      const res = await getRank(params.value)
      rankData.value.push(...res.data.data)
      // 加载状态结束
      loading.value = false
      // 数据全部加载完成
      if (res.data.total === rankData.value.length) {
        finished.value = true
      }
    } catch (error) {
      params.value.page -= 1
    }
  }

  const onLoad = () => {
    params.value.page += 1
    getRankAsync()
  }

  return [rankData, loading, finished, getRankAsync, onLoad]
}
