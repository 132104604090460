import { ref, Ref, computed, ComputedRef } from 'vue'
import { getTotalData } from '@/service/warehouse/requisition'
import { ITotalData } from '@/service/warehouse/requisition/type'

export default function useTotalData(
  params: Ref<{
    date: string
  }>
): [
  Ref<ITotalData>,
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  () => Promise<void>
] {
  const totalData = ref<ITotalData>({
    release_money: 0,
    count: 0
  })
  const getTotalDataAsync = async () => {
    const res = await getTotalData(params.value)
    totalData.value = res.data
  }
  const summaryData = computed(() => {
    return [
      { num: totalData.value?.count, desc: '本月领用次数' },
      { num: totalData.value?.release_money, desc: '领用总金额/万' }
    ]
  })

  return [totalData, summaryData, getTotalDataAsync]
}
